import { useEffect } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EVENT_APPLICATIONS_LABELS, EXPORTS } from '../../../../cms';
import EventApplicationTableRow from './EventApplicationsTableRow';
import { SearchField } from '../../../../common/components';
import { useDebounce } from '../../../../common/hooks/useDebounce';
import './EventApplicationsTab.scss';
import {
  convertToCsv,
  downloadFile,
  formatEventApplicationsForExport,
} from '../../../../cms/Utils';
import { useRemoveUserFromEventMutation } from '../../../../rtk/api/adminApi';
import { useAppDispatch, useAppSelector } from '../../../../rtk/hooks';
import {
  incrementEventApplicationsPageNumber,
  resetEventApplicationsPagination,
  setEventApplicationsSearch,
} from '../../../../rtk/features/eventApplicationsPaginationSlice';
import {
  ApplicationsResponse,
  useGetApplicationsQuery,
  useLazyGetApplicationsForExportQuery,
} from '../../../../rtk/api/applicationApi';

export default function EventApplicationsTab() {
  const dispatch = useAppDispatch();
  const { search, pageNumber, pageSize, refetch } = useAppSelector(
    (state) => state.eventApplicationsPagination,
  );
  const debouncedSearchText = useDebounce(search.trim().toLowerCase());
  const [removeUserFromEvent] = useRemoveUserFromEventMutation();
  const { data } = useGetApplicationsQuery(
    {
      pageNumber,
      pageSize,
      searchText: debouncedSearchText,
      type: 'Event',
      refetch,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const eventApplications = data ? data.values : [];
  const [getEventApplicationsForExport, { isLoading }] =
    useLazyGetApplicationsForExportQuery();

  const hasMore = pageNumber * pageSize === eventApplications.length;

  useEffect(() => {
    return () => {
      dispatch(resetEventApplicationsPagination());
    };
  }, []);

  const handleOnSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEventApplicationsSearch(e.target.value));
  };

  const fetchMoreData = () => {
    dispatch(incrementEventApplicationsPageNumber());
  };

  const handleRemoveUser = (eventId: number, userId: number) => {
    removeUserFromEvent({
      eventId,
      userId,
    });
  };

  const onExport = () => {
    getEventApplicationsForExport({
      pageSize: null,
      pageNumber: null,
      searchText: '',
      type: 'Event',
      refetch: 0,
    })
      .unwrap()
      .then(downloadCsvFile)
      .catch(() => {});
  };

  const downloadCsvFile = (
    eventApplicationsForExport: ApplicationsResponse,
  ) => {
    const formatedEventApplicationsForExport = formatEventApplicationsForExport(
      eventApplicationsForExport.values,
    );
    const csvData = convertToCsv(formatedEventApplicationsForExport);
    downloadFile(csvData, 'event-applications.csv', 'text/csv');
  };

  return (
    <Stack spacing="2rem" className="event-applications">
      <Stack direction="row" spacing="1rem" flexWrap="wrap">
        <SearchField
          placeholder="Search employee by name..."
          value={search}
          onChange={handleOnSearchTextChange}
          sx={{
            width: { xs: '100%', sm: 300 },
          }}
          className="event-applications-search"
        />
        <LoadingButton
          variant="contained"
          onClick={onExport}
          className="event-applications-export-btn"
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
          startIcon={<FileDownloadIcon />}
          loading={isLoading}
          loadingPosition="start"
        >
          {EXPORTS.EXPORT_TO_CSV}
        </LoadingButton>
      </Stack>
      <TableContainer component={Paper}>
        <InfiniteScroll
          dataLength={eventApplications?.length ?? 0}
          next={fetchMoreData}
          hasMore={hasMore!}
          loader=""
          scrollableTarget="scrollableDiv"
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.TITLE}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.USER}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.EMAIL}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.MANAGER}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.SUBMITTED}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.STATUS}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.COST}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.REMOVE_LABEL}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventApplications?.map((row) => (
                <EventApplicationTableRow
                  key={row.id}
                  row={row}
                  handleRemoveUser={handleRemoveUser}
                />
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </TableContainer>
    </Stack>
  );
}
