import { ReactElement, RefObject } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useMediaQuery, useTheme } from '@mui/material';
import ResponsiveRow from './ResponsiveRow/ResponsiveRow';
import Loader from '../Loader/Loader';
import { COLORS, NOT_FOUND_MESSAGES } from '../../../cms';

export type Column<T> = {
  key: keyof T;
  heading?: string;
  primary: boolean;
  hide?: boolean;
  align?: 'left' | 'center' | 'right';
  width?: string;
  render?: (row: T) => ReactElement | null;
};

type ResponsiveTableProps<T> = {
  data: T[];
  columns: Column<T>[];
  isLoading?: boolean;
  loadingRef?: RefObject<HTMLDivElement>
  hasMore?: boolean
};

const ResponsiveTable = <T,>({
  data,
  columns,
  isLoading,
  loadingRef,
  hasMore
}: ResponsiveTableProps<T>) => {
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.down('md'));

  const noDataCell = (
    <TableRow>
      <TableCell
        sx={{ textAlign: 'center', paddingTop: '4rem', paddingBottom: '5rem' }}
        colSpan={6}
        className='no-data-available'
      >
        {NOT_FOUND_MESSAGES.NO_DATA_AVAILABLE}
      </TableCell>
    </TableRow>
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        border: `1px solid ${COLORS.ICON_SECOND} !important`,
      }}
    >
      <Table aria-label="collapsible table" className="responsive-table">
        <TableHead
          sx={{
            '& th': {
              backgroundColor: COLORS.INPUT,
              fontWeight: 'bold',
            },
          }}
        >
          <TableRow>
            {!isScreenMd && (
              <>
                {columns.map(
                  (column, index) =>
                    column.primary && (
                      <TableCell
                        key={index}
                        align={column.align}
                        width={column.width}
                        sx={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {column.heading ?? ''}
                      </TableCell>
                    ),
                )}
              </>
            )}
            {isScreenMd && (
              <>
                {columns.map(
                  (column, index) =>
                    !column.hide && (
                      <TableCell key={index}>{column.heading ?? ''}</TableCell>
                    ),
                )}
              </>
            )}
            <TableCell sx={{ padding: '0' }} width="1%" />
          </TableRow>
        </TableHead>
        <TableBody>
              {data.length === 0
                ? noDataCell
                : data.map((row, index) => (
                    <ResponsiveRow<T> key={index} columns={columns} row={row} />
                  ))}
        </TableBody>
      </Table>
      {!isLoading && hasMore && <div ref={loadingRef}><Loader /></div>}
    </TableContainer>
  );
};

export default ResponsiveTable;
