import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Typography,
  Link as MUILink,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MODAL_CLOSE_BTNS, ROUTE_LINKS } from '../../../cms';
import {
  ENTITY_TYPES,
  ModalTypes,
  NOTIFICATION_TYPES,
} from '../../../cms/enums';
import './Notification.scss';
import { extractInitials, getNotificationMessage } from '../../../cms/Utils';
import { useAppDispatch } from '../../../rtk/hooks';
import { useMarkNotificationAsReadMutation } from '../../../rtk/api/notificationApi';
import { NotificationType } from '../../../cms/types';
import { closeDrawer } from '../../../rtk/features/drawerSlice';
import { openModal } from '../../../rtk/features/modalsSlice';

type NotificationProps = {
  notification: NotificationType;
};

const Notification = ({ notification }: NotificationProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();
  const {
    userName,
    managerName,
    applicantName,
    userPhotoUrl,
    managerPhotoUrl,
    entityType,
    notificationType,
    companyName,
    title,
    entityId,
    applicationId,
    partitionKey,
    rowKey,
    comment,
  } = notification;
  const isDemoRequest = entityType === ENTITY_TYPES.DEMO_REQUEST;
  const isApplication =
    notification.notificationType === NOTIFICATION_TYPES.APPLIED;
  const photo = isApplication ? userPhotoUrl : managerPhotoUrl;
  const sender = isDemoRequest
    ? applicantName
    : isApplication
    ? userName
    : managerName;
  const message = getNotificationMessage(notificationType, entityType);
  const notificationTitle = isDemoRequest ? companyName : title;

  const handleOnTitleClick = () => {
    if (isDemoRequest) {
      navigate(ROUTE_LINKS.SUBMISSIONS);
      markNotificationAsRead({ partitionKey, rowKey });
      dispatch(closeDrawer());
      return;
    }

    if (notificationTitle === null || entityId === null) {
      markNotificationAsRead({ partitionKey, rowKey });
      return;
    }

    if (entityType === ENTITY_TYPES.PATH) {
      navigate(`${ROUTE_LINKS.PERSONAL_PATHS}/${entityId}`);
    }

    // handles both events and trainings
    if (entityType <= 1) {
      setSearchParams((prev) => {
        prev.set(
          entityType === ENTITY_TYPES.EVENT ? 'eventId' : 'trainingId',
          entityId!.toString(), // todo
        );
        return prev;
      });
    }

    if (!isApplication) markNotificationAsRead({ partitionKey, rowKey });
  };

  const onRespondToApplication = (respondType: 'Approve' | 'Reject') => {
    dispatch(
      openModal({
        modalType:
          respondType === 'Approve'
            ? ModalTypes.APPROVE_APPLICATION
            : ModalTypes.REJECT_APPLICATION,
        modalData: {
          id: applicationId,
          type: entityType === ENTITY_TYPES.EVENT ? 'Event' : 'Training',
          title,
          applicantName: userName,
        },
        closeBtn: MODAL_CLOSE_BTNS.INSIDE,
      }),
    );
  };

  return (
    <Stack className="notification" spacing="0.75rem">
      <Box className="notification-body">
        <Avatar
          className="notification-avatar"
          alt="Avatar"
          variant="circular"
          src={photo}
        >
          {extractInitials(sender!)}
        </Avatar>
        <Box className="notification-content">
          <Typography className="notification-content-header" noWrap>
            <span className="notification-content-header-sender">
              {sender ?? 'N/A'}
            </span>{' '}
            {message ?? 'N/A'}
          </Typography>
          <MUILink
            component="button"
            className="notification-content-body"
            onClick={handleOnTitleClick}
          >
            <Typography noWrap> {notificationTitle ?? 'N/A'}</Typography>
          </MUILink>
        </Box>
      </Box>
      {comment && (
        <Accordion
          className="notification-comment"
          elevation={0}
          disableGutters
        >
          <AccordionSummary
            className="notification-comment-summary"
            expandIcon={
              <ExpandMoreIcon className="notification-comment-btn-icon" />
            }
          >
            Comment
          </AccordionSummary>
          <AccordionDetails className="notification-comment-details">
            {comment}
          </AccordionDetails>
        </Accordion>
      )}
      {isApplication && !isDemoRequest && (
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="flex-end"
          spacing="0.75rem"
        >
          <Button
            variant="outlined"
            color="error"
            className="notification-actions-btn"
            onClick={() => onRespondToApplication('Reject')}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            className="notification-actions-btn"
            onClick={() => onRespondToApplication('Approve')}
          >
            Approve
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default Notification;
