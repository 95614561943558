export enum SkillTypes {
  TECHNICAL_SKILLS = 'TechnicalSkills',
  SOFT_SKILLS = 'SoftSkills',
  MANAGEMENT_SKILLS = 'ManagementSkills',
  TECHNICAL = 'Technical',
  CAREER = 'Career',
  PERSONAL = 'Personal',
  INTERNAL = 'Internal',
  DOMAIN = 'Domain',
  OTHER = 'Other',
}

// TODO Remove when old paths are removed
export const PATH_TYPES = [
  { id: 'Personal', name: 'Personal' },
  { id: 'Internal', name: 'Internal' },
  { id: 'Career', name: 'Career' },
  { id: 'Domain', name: 'Domain' },
];

export const NOTIFICATION_TYPES = {
  APPLIED: 0,
  APPROVED: 1,
  REJECTED: 2,
  ASSIGNED: 3,
  PASSWORD_RESET: 4,
  UPDATE: 5,
  REMOVE_USER_FROM_EVENT: 6,
  REMOVE_USER_FROM_TRAINING: 7,
  ASSIGNED_PATH: 8,
};

export const ENTITY_TYPES = {
  TRAINING: 0,
  EVENT: 1,
  DEMO_REQUEST: 2,
  PATH: 3,
};

export const PROFILE_SECTION_MODALS = {
  USER_GUIDE_MODAL: 'userGuide',
  PROFILE_PHOTO_MODAL: 'profilePhoto',
  FEEDBACK_FORM_MODAL: 'feedbackForm',
};

export const LOGIN_METHODS = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export enum ExternalProviders {
  PLURALSIGHT = 'pluralsight',
  UDEMY = 'udemy',
}

export enum PathItemTypes {
  TRAINING = 'Training',
  EVENT = 'Event',
  LINK = 'Link',
  FILE = 'File',
  MEETING = 'Meeting',
}

export enum PathItemStatusType {
  ITEM = 1,
  TRAININIG = 2,
  EVENT = 3,
}

export enum ModalAlignments {
  CENTER = 'center',
  RIGHT = 'right',
}

export const LOCATION_TYPES = ['Online', 'OnSite', 'Internal'] as const;
export type LocationType = (typeof LOCATION_TYPES)[number];

export enum ApplicationStatuses {
  PENDING = 'PendingApproval',
  APPROVED = 'Approved',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
  DROPPED = 'Dropped',
  ALL = 'All',
}

export enum ApplicationStatusesNumbers {
  PENDING = 1,
  APPROVED = 2,
  IN_PROGRESS = 3,
  COMPLETED = 5,
  REJECTED = 7,
  DROPPED = 8,
}

export enum MyTrainingsAndEventsTypes {
  TRAINING = 'Training',
  EVENT = 'Event',
  ALL = 'All',
}

export enum PathStatuses {
  NOT_STARTED = 'NotStarted',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  DROPPED = 'Dropped',
  APPROVED = 'Approved', // to be removed when BE only returns "NotStarted"
  ALL = 'All',
}

// items of types training and event will hanve an initial status of Approved, the rest NotStarted
export enum PathItemStatuses {
  APPROVED = 'Approved',
  NOT_STARTED = 'NotStarted',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  DROPPED = 'Dropped',
}

export enum DemoRequestStatuses {
  ALL = 'All',
  PENDING = 'PendingApproval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum MyEmployeesModals {
  EMPLOYEES_SELECT = 'EmployeesSelect',
  EVENTS_SELECT = 'EventsSelect',
  TRAININGS_SELECT = 'TrainingsSelect',
}

export enum ModalTypes {
  EVENT_DETAILS = 'event_details',
  EVENT_FORM = 'event_form',
  LOGIN_FORM = 'login_form',
  REQUEST_DEMO_FORM = 'request_demo_form',
  TRAINING_FORM = 'training_form',
  RATE_TRAINING_FORM = 'rate_training_form',
  TRAINING_DETAILS = 'training_details',
  SUCCESS_MESSAGE = 'success_message',
  ERROR_MESSAGE = 'error_message',
  APPROVE_APPLICATION = 'approve_application',
  REJECT_APPLICATION = 'reject_application',
  CONFIRM_ACTION = 'confirm_action',
  PAID_TRAINING_NOTE = 'paid_training_note',
}

//  List with all Gogole Tag Manager trigger events
export enum DataLayerEvents {
  SUBMIT_CONTACT_FORM = 'submitContactForm',
  SUBMIT_DEMO_REQUEST_FORM = 'submitDemoRequestForm',
}

export enum ApplicationType {
  TRAININIG = 1,
  EVENT = 2,
}
