import { useEffect } from 'react';
import dayjs from 'dayjs';
import './TrainingDetails.scss';
import { GiReceiveMoney } from 'react-icons/gi';
import { BiCalendarEvent, BiCalendarAlt } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, Divider, Stack, useMediaQuery, useTheme } from '@mui/material';
import { TRAINING_DETAIL_LABELS, DATE_FORMATS, ROLES, COLORS } from '../../cms';
import TrainingDetailsCard from './TrainingDetailsCard/TrainingDetailsCard';
import AssignTraining from '../AssignTraining/AssignTraining';
import TrainingDetailsHeader from './TrainingDetailsHeader/TrainingDetailsHeader';
import participantsIcon from '../../common/images/participants-icon.png';
import { getUserRoles } from '../../common/userRoleRetriever';
import { useAppSelector } from '../../rtk/hooks';
import { useGetTrainingByIdQuery } from '../../rtk/api/trainingApi';
import { SectionLoader } from '../../common/components';
import EventDetailsItem from '../EventDetails/EventDetailsItem/EventDetailsItem';
import CustomTabsWithOutlet from '../../common/components/CustomTabsWithOutlet/CustomTabsWithOutlet';
import TrainingDetailsOverview from '../DetailsOverview/TrainingDetailsOverview/TrainingDetailsOverview';
import TrainingDetailsReviews from './TrainingDetailsReviews/TrainingDetailsReviews';

type TrainingDetailsProps = {
  trainingId: number;
  onClose: () => void;
};

export default function TrainingDetails({
  trainingId,
  onClose,
}: TrainingDetailsProps) {
  const theme = useTheme();
  const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));

  const { isUserLoggedIn } = useAppSelector((state) => state.userSession);

  const { data: training, isError } = useGetTrainingByIdQuery(trainingId);

  const isAdmin = isUserLoggedIn && getUserRoles().includes(ROLES.ADMIN_ROLE);
  const isManager =
    isUserLoggedIn && getUserRoles().includes(ROLES.MANAGER_ROLE);

  const trainingDetailsItems = training && [
    ...(training.trainerFirstName || training.trainerLastName
      ? [
          {
            name: TRAINING_DETAIL_LABELS.TRAINER,
            icon: <CgProfile size={24} color={COLORS.SECONDARY} />,
            value: `${training.trainerFirstName || ''} ${
              training.trainerLastName || ''
            }`,
          },
        ]
      : []),
    {
      name: TRAINING_DETAIL_LABELS.PRICE,
      icon: <GiReceiveMoney size={24} color={COLORS.SECONDARY} />,
      value: `${training.cost} €`,
    },
    {
      name: TRAINING_DETAIL_LABELS.DATES,
      icon: <BiCalendarEvent size={24} color={COLORS.SECONDARY} />,
      value:
        training.startDate && training.endDate
          ? `${dayjs(training.startDate).format(DATE_FORMATS.NORMAL)} - 
        ${dayjs(training.endDate).format(DATE_FORMATS.NORMAL)}`
          : TRAINING_DETAIL_LABELS.SELF_PACED,
    },
    {
      name: TRAINING_DETAIL_LABELS.APPLY_BEFORE,
      icon: <BiCalendarAlt size={24} color={COLORS.SECONDARY} />,
      value: training.applyBeforeDate
        ? dayjs(new Date(training.applyBeforeDate)).format(DATE_FORMATS.NORMAL)
        : TRAINING_DETAIL_LABELS.NO_DEADLINE,
    },
    {
      name: TRAINING_DETAIL_LABELS.LOCATION,
      icon: <MdLocationOn size={24} color={COLORS.SECONDARY} />,
      value:
        training.location === 'Online'
          ? training.location
          : training.trainingLocationAddress,
    },
    ...(training.applicationsLimit !== null && training.applicationsLimit > 0
      ? [
          {
            name: TRAINING_DETAIL_LABELS.PARTICIPANTS,
            icon: <img src={participantsIcon} alt="Participants" width={24} />,
            value: `${
              training.appliedParticipants ??
              TRAINING_DETAIL_LABELS.NOT_AVAILABLE
            } (of ${training.applicationsLimit} available)`,
          },
        ]
      : []),
  ];

  const tabs = [
    {
      label: TRAINING_DETAIL_LABELS.TRAINING_OVERVIEW,
      element: (
        <TrainingDetailsOverview description={training?.description || ''} />
      ),
    },
    {
      label: TRAINING_DETAIL_LABELS.TRAINING_REVIEWS,
      element: <TrainingDetailsReviews comments={training?.comments || []} />,
    },
  ];

  useEffect(() => {
    if (isError) onClose();
  }, [isError]);

  if (!training) return <SectionLoader />;

  return (
    <>
      <Stack
        direction="column"
        gap="1.5rem"
        sx={{
          padding: '2rem',
        }}
        className="training-details"
      >
        {training && (
          <TrainingDetailsHeader training={training} isAdmin={isAdmin} />
        )}
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          flexWrap="wrap"
          gap="1.5rem"
          divider={!isScreenXS && <Divider orientation="vertical" flexItem />}
        >
          {trainingDetailsItems?.map((item) => (
            <EventDetailsItem key={item.name} item={item} />
          ))}
        </Stack>
        <Stack direction="row" flexWrap="wrap">
          {training.url?.length > 0 && (
            <Button
              component="a"
              target="_blank"
              color="secondary"
              startIcon={<LaunchIcon />}
              href={training.url}
              className="training-details-btn-link"
            >
              {TRAINING_DETAIL_LABELS.PREVIEW_TRAINING}
            </Button>
          )}
          {training?.filesUrls &&
            training?.filesUrls.length > 0 &&
            training?.filesUrls.map((file, index) => (
              <Button
                key={index}
                component="a"
                target="_blank"
                color="primary"
                startIcon={<LaunchIcon />}
                href={file}
              >
                {`${TRAINING_DETAIL_LABELS.DOWNLOAD_FILES} ${index + 1}`}
              </Button>
            ))}
          {(isAdmin || isManager) && !training?.isArchived && (
            <AssignTraining trainingId={training?.id} />
          )}
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          gap="4.6rem"
          className="training-details-sections"
        >
          <TrainingDetailsCard
            lessons={training?.lessons}
            duration={training?.duration}
            level={training?.level}
          />
          <Stack flex="65%">
            <CustomTabsWithOutlet tabs={tabs} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
