import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { MenuItem, Stack, Typography } from '@mui/material';
import {
  MyTeamApplication,
  useGetMyTeamApplicationsQuery,
} from '../../../rtk/api/adminApi';
import ResponsiveTable from '../../../common/components/ResponsiveTable/ResponsiveTable';
import EmpployeeBio from '../MyEmployeesTab/EmployeeBio/EmployeeBio';
import {
  ApplicationStatuses,
  MyTrainingsAndEventsTypes,
} from '../../../cms/enums';
import {
  APPLICATION_STATUSES,
  DATE_FORMATS,
  MY_GROUP_LABELS,
  MY_TRAININGS_AND_EVENTS_TYPES,
} from '../../../cms';
import { inheritFontStyle } from '../../../cms/Utils';
import ApplicationActions from './ApplicationActions/ApplicationActions';
import {
  ApplicationStatusChip,
  FilterSelect,
  SearchField,
} from '../../../common/components';
import { useDebounce } from '../../../common/hooks/useDebounce';

export type TypeFilter = MyTrainingsAndEventsTypes | 'All';
export type StatusFilter = ApplicationStatuses | 'All';

const RequestsTab = () => {
  const [, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search.trim().toLowerCase());
  const [type, setType] = useState<TypeFilter>('All');
  const [status, setStatus] = useState<StatusFilter>(
    ApplicationStatuses.PENDING,
  );
  const { data: apps, isFetching } = useGetMyTeamApplicationsQuery({
    search: debouncedSearch,
    type,
    status,
  });

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedType = e.target.value as TypeFilter;
    setType(selectedType);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedStatus = e.target.value as StatusFilter;
    setStatus(selectedStatus);
  };

  const handleTitleClick = (
    cardType: MyTrainingsAndEventsTypes,
    id: number,
  ) => {
    const idType = cardType === 'Training' ? 'trainingId' : 'eventId';

    setSearchParams((prev) => {
      prev.set(idType, id.toString());
      return prev;
    });
  };

  return (
    <Stack spacing="2rem" marginTop="1.25rem">
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="flex-end"
        spacing="1rem"
      >
        <SearchField
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder={MY_GROUP_LABELS.SEARCH_EMPLOYEE_PLACEHOLDER}
          sx={{
            width: { xs: '100%', sm: 300 },
            marginRight: 'auto',
          }}
        />

        <FilterSelect
          label="Type"
          onChange={handleTypeChange}
          value={type}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <MenuItem key="All" value="All">
            All
          </MenuItem>
          {MY_TRAININGS_AND_EVENTS_TYPES.map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {s.value}
            </MenuItem>
          ))}
        </FilterSelect>

        <FilterSelect
          label="Status"
          onChange={handleStatusChange}
          value={status}
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <MenuItem key="All" value="All">
            All
          </MenuItem>
          {APPLICATION_STATUSES.map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {s.label}
            </MenuItem>
          ))}
        </FilterSelect>
      </Stack>

      <ResponsiveTable<MyTeamApplication>
        data={apps || []}
        isLoading={isFetching}
        columns={[
          {
            key: 'user',
            heading: 'From',
            hide: true,
            primary: true,
            width: '20%',
            render: (item: MyTeamApplication) => (
              <EmpployeeBio
                name={`${item.user.firstName} ${item.user.lastName}`}
                photoUrl={item.user.photoUrl}
              />
            ),
          },
          {
            key: 'title',
            heading: 'About',
            hide: false,
            primary: true,
            width: '40%',
            render: (item: MyTeamApplication) => (
              <Stack>
                <Typography
                  sx={{
                    ...inheritFontStyle,
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    wordBreak: 'break-all',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() =>
                    handleTitleClick(
                      item.type,
                      item.training?.id ?? item.event?.id,
                    )
                  }
                >
                  {item.event?.title ?? item.training?.title}
                </Typography>
                <Typography sx={{ ...inheritFontStyle }}>
                  {item.type}
                </Typography>
              </Stack>
            ),
          },
          {
            key: 'createdOn',
            heading: 'Submitted',
            hide: true,
            primary: true,
            width: '10%',
            render: (item: MyTeamApplication) => (
              <Typography sx={{ ...inheritFontStyle, whiteSpace: 'nowrap' }}>
                {dayjs(new Date(item.createdOn)).format(DATE_FORMATS.NORMAL)}
              </Typography>
            ),
          },
          {
            key: 'status',
            heading: 'Status',
            hide: true,
            primary: true,
            width: '10%',
            render: (item: MyTeamApplication) => (
              <ApplicationStatusChip status={item.status} />
            ),
          },
          {
            key: 'id',
            heading: 'Actions',
            hide: true,
            primary: true,
            width: '10%',
            render: (item: MyTeamApplication) =>
              item.status === ApplicationStatuses.PENDING ? (
                <ApplicationActions application={item} />
              ) : null,
          },
          {
            key: 'comment',
            heading: 'Comment',
            hide: true,
            primary: false,
          },
        ]}
      />
    </Stack>
  );
};

export default RequestsTab;
