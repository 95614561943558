import { useState } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DATE_FORMATS, TRAINING_APPLICATIONS_LABELS } from '../../../../cms';
import { ApplicationStatuses } from '../../../../cms/enums';
import { getApplicationStatusFromNumber } from '../../../../cms/Utils';
import { ApplicationStatusChip } from '../../../../common/components';

type TrainingApplicationTableRowProps = {
  row: any;
  removeUser: (trainingId: number, userId: number) => void;
};

export default function TrainingApplicationTableRow({
  row,
  removeUser,
}: TrainingApplicationTableRowProps) {
  const {
    status: statusNumber,
    training,
    user,
    createdOn,
    approvedOn,
    rejectedOn,
    startedOn,
    completedOn,
  } = row;
  const [open, setOpen] = useState(false);

  const status = getApplicationStatusFromNumber(statusNumber);

  const showRemoveBtn = [
    ApplicationStatuses.PENDING,
    ApplicationStatuses.APPROVED,
    ApplicationStatuses.IN_PROGRESS,
  ].includes(status);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'none !important' } }}>
        <TableCell size="small">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{training?.title}</TableCell>
        <TableCell align="left">{`${user.firstName} ${user.lastName}`}</TableCell>
        <TableCell align="left">{user.email}</TableCell>
        <TableCell align="left">{user.manager}</TableCell>
        <TableCell align="left">
          {dayjs(createdOn).format(DATE_FORMATS.FULL)}
        </TableCell>
        <TableCell align="left">{training?.type}</TableCell>
        <TableCell size="small" align="left">
          {training?.cost}
        </TableCell>
        <TableCell align="left">
          <ApplicationStatusChip status={status} />
        </TableCell>
        {showRemoveBtn && (
          <TableCell align="left">
            <IconButton
              className="remove-employee-btn"
              color="error"
              onClick={() => removeUser(training?.id, user.id)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="ml-5">
                <Typography
                  gutterBottom
                  component="div"
                  className="detail-information"
                >
                  {TRAINING_APPLICATIONS_LABELS.DETAIL_INFORMATION}
                </Typography>
                <Typography gutterBottom component="div" className="row">
                  <div className="mr-2 column">
                    {TRAINING_APPLICATIONS_LABELS.APPLIED_DATE}
                  </div>{' '}
                  {createdOn !== null
                    ? dayjs(createdOn).format(DATE_FORMATS.FULL)
                    : 'N/A'}
                </Typography>
                <Typography gutterBottom component="div" className="row">
                  <div className="mr-2 column">
                    {TRAINING_APPLICATIONS_LABELS.APPROVED_DATE}
                  </div>{' '}
                  {approvedOn !== null
                    ? dayjs(approvedOn).format(DATE_FORMATS.FULL)
                    : 'N/A'}
                </Typography>
                <Typography gutterBottom component="div" className="row">
                  <div className="mr-2 column">
                    {TRAINING_APPLICATIONS_LABELS.REJECTED_DATE}
                  </div>{' '}
                  {rejectedOn !== null
                    ? dayjs(rejectedOn).format(DATE_FORMATS.FULL)
                    : 'N/A'}
                </Typography>
                <Typography gutterBottom component="div" className="row">
                  <div className="mr-2 column">
                    {TRAINING_APPLICATIONS_LABELS.STARTED_DATE}
                  </div>{' '}
                  {startedOn !== null
                    ? dayjs(startedOn).format(DATE_FORMATS.FULL)
                    : 'N/A'}
                </Typography>
                <Typography gutterBottom component="div" className="row">
                  <div className="mr-2 column">
                    {TRAINING_APPLICATIONS_LABELS.COMPLETED_DATE}
                  </div>{' '}
                  {completedOn !== null
                    ? dayjs(completedOn).format(DATE_FORMATS.FULL)
                    : 'N/A'}
                </Typography>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
