import { AnyAction, Reducer, combineReducers } from '@reduxjs/toolkit';
import userImportReducer from '../reducers/userImportReducer';
import applicationVersionReducer from '../reducers/applicationVersionReducer';
import employeesReducer from '../reducers/employeesReducer';
import groupsReducer from '../reducers/groupsReducer';
import statisticsReducer from '../reducers/statisticsReducer';
import validationErrorsReducer from '../reducers/validationErrorsReducer';

import { api } from './api/api';
import drawerSlice from './features/drawerSlice';
import preloaderSlice from './features/preloaderSlice';
import userSessionSlice from './features/userSessionSlice';
import eventsPaginationSlice from './features/eventsPaginationSlice';
import trainingsPaginationSlice from './features/trainingsPaginationSlice';
import modalsSlice from './features/modalsSlice';
import demoRequestsPaginationSlice from './features/demoRequestsPaginationSlice';
import pathsPagincationSlice from './features/pathsPagincationSlice';
import myTeamReducer from '../reducers/myTeamReducer';
import trainingApplicationsPaginationSlice from './features/trainingApplicationsPaginationSlice';
import eventApplicationsPaginationSlice from './features/eventApplicationsPaginationSlice';
import myEmployeesApplicationsPaginationSlice from './features/myEmployeesApplicationsPaginationSlice';

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  drawer: drawerSlice,
  preloader: preloaderSlice,
  userSession: userSessionSlice,
  eventsPagination: eventsPaginationSlice,
  trainingsPagination: trainingsPaginationSlice,
  eventApplicationsPagination: eventApplicationsPaginationSlice,
  trainingApplicationsPagination: trainingApplicationsPaginationSlice,
  pathsPagination: pathsPagincationSlice,
  demoRequestsPagination: demoRequestsPaginationSlice,
  myEmployeesApplicationsPagination: myEmployeesApplicationsPaginationSlice,
  modals: modalsSlice,

  myTeam: myTeamReducer,
  applicationVersion: applicationVersionReducer,
  allEmployees: employeesReducer,
  groups: groupsReducer,
  userImport: userImportReducer,
  statistics: statisticsReducer,
  validationErrors: validationErrorsReducer,
});

const rootReducer: Reducer = (state, action: AnyAction) => {
  if (action.type === 'store/reset') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const resetReduxStore = () => ({ type: 'store/reset' });
export type RootState = ReturnType<typeof appReducer>;
export default rootReducer;
