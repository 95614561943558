import React, { useEffect } from 'react';
import { Dayjs } from 'dayjs';
import { Stack } from '@mui/material';
import { CustomDateTimePicker } from '../../../../common/components';
import { DATE_FORMATS } from '../../../../cms';

interface CostsTabDateRangeProps {
  onFromDateChange: Function;
  onToDateChange: Function;
}

const CostsTabDateRange = ({
  onFromDateChange,
  onToDateChange,
}: CostsTabDateRangeProps) => {
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(null);
  const [toDate, setToDate] = React.useState<Dayjs | null>(null);

  useEffect(() => {
    onFromDateChange(fromDate?.format(DATE_FORMATS.SIMPLE_SLASH));
  }, [fromDate]);

  useEffect(() => {
    onToDateChange(toDate?.format(DATE_FORMATS.SIMPLE_SLASH));
  }, [toDate]);

  return (
    <Stack
      direction="row"
      spacing="1rem"
      sx={{
        width: 'auto',
      }}
    >
      <CustomDateTimePicker
        label="From Date"
        className="from-date"
        value={fromDate}
        onChange={(newValue: any) => {
          setFromDate(newValue);
        }}
        maxDate={toDate}
        views={['year', 'month', 'day']}
        sx={{
          width: 'auto',
        }}
      />
      <CustomDateTimePicker
        label="To Date"
        className="to-date"
        value={toDate}
        onChange={(newValue: any) => {
          setToDate(newValue);
        }}
        minDate={fromDate}
        views={['year', 'month', 'day']}
        sx={{
          width: 'auto',
        }}
      />
    </Stack>
  );
};

export default CostsTabDateRange;
