import config from '../../config';

export const LOGIN_SERVICE_URL = `users/login`;
export const LOGOUT_SERVICE_URL = `users/logout`;
export const REFRESH_TOKEN_URL = `users/refresh`;
export const REVOKE_TOKEN_URL = `users/revoke`;
export const EXTERNAL_LOGIN_SERVICE_URL = `users/loginExternal`;
export const UPDATE_IMAGE_URL = `users/updateImage`;

export const GET_TRAININGS_URL = `training`;
export const GET_PRIVATE_TRAININGS_URL = `training/allprivate`;

export const GET_EVENTS_URL = `event`;
export const EVENT_UPLOAD_PDF_URL = `event/uploadpdf`;
export const REMOVE_USER_FROM_EVENT_URL = `admin/removeUserFromEvent`;
export const REMOVE_USER_FROM_TRAINING_URL = `admin/removeUserFromTraining`;
export const ASSIGN_EVENT_URL = `event/assign`;
export const APPLY_FOR_EVENT_URL = `event/apply`;
export const GET_MY_EVENTS_URL = `event/mine`;

export const GET_ALL_EVENT_APPLICATIONS_URL = `EventApplication`;

export const CREATE_GROUP_URL = `Group/create/`;
export const EDIT_GROUP_URL = `Group/edit/`;
export const ADD_MEMBERS_TO_GROUP_URL = `Group/addMembers/`;
export const ASSIGN_TRAININGS_TO_GROUP_URL = `Group/assignTrainings/`;
export const REMOVE_MEMBER_FROM_GROUP_URL = `Group/removeUser/`;
export const REMOVE_TRAINING_FROM_GROUP_URL = `Group/removeGroupTraining/`;
export const SEND_NOTIFICIONS_FOR_ASSIGNED_TRAININGS_URL = `Group/sendNotifications/`;
export const GET_ALL_GROUPS_URL = `Group/getGroups`;
export const GET_GROUPS_URL = `Group/all`;
export const GET_GROUP_URL = `Group`;
export const DELETE_GROUP_URL = `Group/delete`;

export const GET_ALL_TRAINING_TYPES_URL = `lookup/training/types`;
export const GET_ALL_EVENT_TYPES_URL = `lookup/event/types`;
export const GET_TRAINING_LOCATIONS_URL = `lookup/locations`;
export const CREATE_TRAINING_URL = `training/create`;
export const EDIT_TRAINING_URL = `training/edit`;
export const APPLY_FOR_TRAININGS_URL = `training/apply`;
export const ASSIGN_TRAINING_TO_EMPLOYEES_URL = `training/assignTraining`;
export const RATE_TRAINING_URL = `training/rate`;

export const GET_EMPLOYEE_STATS_URL = `statistics/employee`;
export const GET_ALL_COST_REPORTS_URL = `statistics/costReports`;

export const GET_ALL_TRAINING_STATISTICS_URL = `Statistics/trainings`;
export const GET_ALL_EVENTS_STATISTICS_URL = `Statistics/events`;
export const GET_PATHS_STATISTICS_URL = `statistics/paths`;

export const GET_ADMIN_URL = `admin`;
export const ARCHIVE_EMPLOYEE_URL = `admin/archiveEmployees`;
export const DEACTIVATE_EMPLOYEE_URL = `admin/deactivateEmployees`;
export const GET_MY_TEAM_SUFFIX = '/myteam';
export const GET_ALL_MANAGERS = `admin/managers`;
export const GET_ALL_PROFESSIONS = `admin/professions`;
export const GET_ALL_DEPARTMENTS = `admin/departments`;
export const EDIT_EMPLOYEE = `admin/employee`;

export const GET_ALL_EMPLOYEES_URL = `employees/all`;
export const GET_SINGLE_EMPLOYEE_URL = `employees`;
export const ADD_EMPLOYEE_TO_MY_TEAM_URL = `employees/myteam/`;
export const REMOVE_EMPLOYEE_FROM_MY_TEAM_URL = `employees/removeEmployee/`;

export const PATHS_URL = `paths`;
export const ASSIGN_PATH_TO_USERS_URL = `paths/assign`;
export const UNASSIGN_PATH_FROM_USERS_URL = `paths/unassign`;
export const DROP_PATH_URL = `paths/drop`;
export const PERSONAL_PATHS_URL = `paths/personal`;
export const PERSONAL_PATH_URL_SUFFIX = 'personal';
export const PATH_UPLOAD_FILES_URL = 'paths/UploadFile';

export const APPLICATIONS_URL = `Applications`;
export const PERSONAL_APPLICATIONS_URL = 'Applications/mine';
export const MY_EMPLOYEES_APPLICATIONS = 'Applications/myemployees';
export const APPROVE_APPLICATION_URL = 'Applications/approve';
export const REJECT_APPLICATION_URL = 'Applications/reject';

export const GET_ALL_TRAINING_APPLICATIONS_URL = `TrainingApplication`;

export const GET_TRAINING_URL = `training`;
export const GET_EXPIRED_TRAINING_URL = `training/expired`;
export const DELETE_TRAINING_URL = `training/delete`;
export const GET_MY_TRAININGS_URL = `training/mine`;
export const GET_TRAINING_BY_EXTERNAL_LINK_URL = `training/prefill/external`;
export const TRAINING_UPLOAD_PDF_URL = `training/uploadpdf/`;

export const GET_ALL_PATHS_FOR_ASSIGNING = `paths/all`;

export const MOVE_TRAINING_URL = `paths/move/training`;
export const GET_AVAILABLE_PATHS = `paths/available/`;
export const DELETE_PATH = `paths/delete/`;
export const EDIT_PATH = `paths/edit`;
export const CREATE_PATH = `paths/create`;

export const CREATE_DEMO_REQUEST_URL = `DemoRequest/create`;
export const GET_ALL_DEMO_REQUESTS_URL = `DemoRequest/getDemoRequests`;
export const DELETE_DEMO_REQUESTS_URL = `DemoRequest/delete`;
export const APPROVE_DEMO_REQUEST_URL = `DemoRequest/approve`;
export const REJECT_DEMO_REQUEST_URL = `DemoRequest/reject`;

// Import Users Csv
export const IMPORT_USERS_CSV_URL = `UserImport/CsvImport`;

// Contact
export const CONTACT_US_URL = `contacts/contactUs`;

// signalR
export const NOTIFICATIONS_HUB = `${config.HUB_PREFIX}/notifications`;
