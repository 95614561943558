import { useEffect } from 'react';
import {
  BaseModal,
  ConfirmAction,
  ErrorMessage,
  SuccessMessage,
} from '../../common/components';
import { closeModal, openModal } from '../../rtk/features/modalsSlice';
import { useAppDispatch, useAppSelector } from '../../rtk/hooks';
import { MODAL_CLOSE_BTNS, ROUTE_LINKS } from '../../cms';
import EventDetails from '../EventDetails/EventDetails';
import TrainingDetails from '../TrainingDetails/TrainingDetails';
import { useUrlParams } from '../../common/hooks/useUrlParams';
import { ModalState } from '../../cms/types';
import {
  EventForm,
  LoginForm,
  RateTrainingForm,
  RequestDemoForm,
  TrainingForm,
} from '../../forms';
import ApplicationActionModal from '../../common/components/ApplicationActionModal/ApplicationActionModal';
import { ModalTypes } from '../../cms/enums';
import { useNavigateBack } from '../../common/hooks/useNavigateBack';
import PaidTrainingNoteModal from '../../common/components/PaidTrainingNoteModal/PaidTrainingNoteModal';

const AppModals = () => {
  const { eventId, trainingId } = useUrlParams();
  const dispatch = useAppDispatch();
  const { modals } = useAppSelector((state) => state.modals);
  const navigateBack = useNavigateBack();

  useEffect(() => {
    // Open modal when url has params
    if (eventId) {
      dispatch(
        openModal({
          modalType: ModalTypes.EVENT_DETAILS,
          modalData: eventId,
          closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
        }),
      );
    } else {
      dispatch(closeModal(ModalTypes.EVENT_DETAILS));
    }
  }, [eventId]);

  useEffect(() => {
    if (trainingId) {
      dispatch(
        openModal({
          modalType: ModalTypes.TRAINING_DETAILS,
          modalData: trainingId,
          closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
        }),
      );
    } else {
      dispatch(closeModal(ModalTypes.TRAINING_DETAILS));
    }
  }, [trainingId]);

  const handleOnClose = (modalType: ModalTypes) => {
    if (modalType === ModalTypes.TRAINING_DETAILS) {
      navigateBack({ fallback: ROUTE_LINKS.TRAININGS });
    } else if (modalType === ModalTypes.EVENT_DETAILS) {
      navigateBack({ fallback: ROUTE_LINKS.EVENTS });
    } else {
      dispatch(closeModal(modalType));
    }
  };

  // Global modals
  const appModals = ({ modalType, modalData }: ModalState) => {
    switch (modalType) {
      case ModalTypes.SUCCESS_MESSAGE:
        return <SuccessMessage text={modalData} />;
      case ModalTypes.ERROR_MESSAGE:
        return <ErrorMessage text={modalData} />;
      case ModalTypes.APPROVE_APPLICATION:
        return (
          <ApplicationActionModal
            actionType="Approve"
            applicationId={modalData.id}
            applicationType={modalData.type}
            title={modalData.title}
            applicantName={modalData.applicantName}
            onClose={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.REJECT_APPLICATION:
        return (
          <ApplicationActionModal
            actionType="Reject"
            applicationId={modalData.id}
            applicationType={modalData.type}
            title={modalData.title}
            applicantName={modalData.applicantName}
            onClose={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.CONFIRM_ACTION:
        return (
          <ConfirmAction
            text={modalData.text}
            btnText={modalData.btnText}
            onConfirm={modalData.onConfirm}
            onClose={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.PAID_TRAINING_NOTE:
        return (
          <PaidTrainingNoteModal
            type={modalData.type}
            trainingTitle={modalData.title}
            onConfirm={modalData.onConfirm}
            onClose={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.EVENT_DETAILS:
        return (
          <EventDetails
            eventId={modalData}
            onClose={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.TRAINING_DETAILS:
        return (
          <TrainingDetails
            trainingId={modalData}
            onClose={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.EVENT_FORM:
        return (
          <EventForm
            initialFormData={modalData.initialFormData}
            type={modalData.type}
            onCloseHandler={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.TRAINING_FORM:
        return (
          <TrainingForm
            trainingData={modalData.trainingData}
            type={modalData.type}
            onCloseHandler={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.RATE_TRAINING_FORM:
        return (
          <RateTrainingForm
            trainingId={modalData.trainingId}
            trainingTitle={modalData.trainingTitle}
            currentRating={modalData.currentRating}
            onClose={() => handleOnClose(modalType)}
          />
        );
      case ModalTypes.LOGIN_FORM:
        return <LoginForm />;
      case ModalTypes.REQUEST_DEMO_FORM:
        return <RequestDemoForm />;
      default:
        return null;
    }
  };

  return (
    <>
      {modals.map((modal) => (
        <BaseModal
          timeout={modal.timeout}
          key={modal.modalType}
          open
          onClose={() => handleOnClose(modal.modalType)}
          hideCloseBtn={modal.closeBtn === MODAL_CLOSE_BTNS.HIDDEN}
          showCloseBtnOutside={modal.closeBtn === MODAL_CLOSE_BTNS.OUTSIDE}
        >
          {appModals(modal)}
        </BaseModal>
      ))}
    </>
  );
};

export default AppModals;
