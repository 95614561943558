import { useEffect, useRef, useState } from 'react';
import { Avatar, Stack } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import ResponsiveTable from '../../../common/components/ResponsiveTable/ResponsiveTable';
import {
  ApplicationsResponse,
  useGetMyEmployeesApplicationsQuery,
} from '../../../rtk/api/applicationApi';
import { Application } from '../../../cms/types/applcationTypes';
import { useDebounce } from '../../../common/hooks/useDebounce';
import { useAppSelector } from '../../../rtk/hooks';
import { SearchField } from '../../../common/components';
import { DATE_FORMATS, EXPORTS } from '../../../cms';
import {
  incrementMyEmployeesApplicationsPageNumber,
  resetMyEmployeesApplicationPagination,
  setMyEmployeesApplicationsFromDate,
  setMyEmployeesApplicationsSearchText,
  setMyEmployeesApplicationsToDate,
} from '../../../rtk/features/myEmployeesApplicationsPaginationSlice';
import CostsTabDateRange from './CostsTabDateRange/CostsTabDateRange';
import {
  convertToCsv,
  downloadFile,
  formatMyEmployeesApplicationsExport,
} from '../../../cms/Utils';

const CostsTab = () => {
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const myEmployeesApplicationsPagination = useAppSelector(
    (state) => state.myEmployeesApplicationsPagination,
  );
  const debouncedSearchValue = useDebounce(
    myEmployeesApplicationsPagination.searchText.trim().toLowerCase(),
    300,
  );
  const { data: applicationsData, isFetching } =
    useGetMyEmployeesApplicationsQuery(
      {
        ...myEmployeesApplicationsPagination,
        searchText: debouncedSearchValue,
        dateFrom,
        dateTo,
      },
      {
        refetchOnMountOrArgChange: true,
      },
    );
  const { values = [], totalCount = 0 } = applicationsData || {};
  const hasMoreApplications: boolean =
    totalCount -
      myEmployeesApplicationsPagination.pageNumber *
        myEmployeesApplicationsPagination.pageSize >
    0;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMyEmployeesApplicationsSearchText(e.target.value));
  };

  const onFromDateChange = (newFromDate: string) => {
    if (newFromDate !== undefined) {
      const date = dayjs(newFromDate, DATE_FORMATS.SIMPLE_DASH)
        .startOf('day')
        .utcOffset('+0200')
        .toDate();
      setDateFrom(date);
      dispatch(setMyEmployeesApplicationsFromDate(date));
    }
  };

  const onToDateChange = (newToDate: string) => {
    if (newToDate !== undefined) {
      const date = dayjs(newToDate, DATE_FORMATS.SIMPLE_DASH)
        .endOf('day')
        .utcOffset('+0200')
        .toDate();
      setDateTo(date);
      dispatch(setMyEmployeesApplicationsToDate(date));
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMoreApplications) fetchMoreData();
    });

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => observer.disconnect();
  }, [hasMoreApplications, isFetching]);

  const loadingRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => {
      dispatch(resetMyEmployeesApplicationPagination());
    };
  }, []);

  const fetchMoreData = () => {
    dispatch(incrementMyEmployeesApplicationsPageNumber());
  };

  const onExport = () => {
    if (applicationsData) downloadCsvFile(applicationsData);
  };

  const downloadCsvFile = (applicationsForExport: ApplicationsResponse) => {
    const formatedApplicationsForExport = formatMyEmployeesApplicationsExport(
      applicationsForExport.values,
    );
    const csvData = convertToCsv(formatedApplicationsForExport);
    downloadFile(csvData, 'my-employees-applications.csv', 'text/csv');
  };

  return (
    <Stack>
      <Stack spacing="2rem" marginTop="2.5rem" marginBottom="2.5rem">
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-end"
          spacing="1rem"
        >
          <span
            style={{
              fontSize: '20px',
              height: '100%',
              paddingBottom: '0.3rem',
              fontWeight: 'bold',
            }}
          >
            Costs ({values?.length ?? 0})
          </span>
          <SearchField
            value={myEmployeesApplicationsPagination.searchText}
            onChange={handleSearchChange}
            placeholder="Search employee by name..."
            sx={{
              width: { xs: '100%', sm: 300 },
              marginRight: 'auto',
            }}
          />
          <CostsTabDateRange
            onFromDateChange={onFromDateChange}
            onToDateChange={onToDateChange}
          />
          <LoadingButton
            variant="contained"
            onClick={onExport}
            className="training-applications-export-btn"
            sx={{
              width: { xs: '100%', sm: 'auto' },
            }}
            startIcon={<FileDownloadIcon />}
            loadingPosition="start"
          >
            {EXPORTS.EXPORT_DATA}
          </LoadingButton>
        </Stack>
      </Stack>
      <ResponsiveTable<Application>
        data={values || []}
        isLoading={isFetching}
        hasMore={hasMoreApplications}
        loadingRef={loadingRef}
        columns={[
          {
            key: 'user',
            heading: 'From',
            primary: true,
            width: '20%',
            render: (item: Application) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{}}>
                  <Avatar
                    alt="Employee"
                    className="employee-avatar"
                    src={item.user.photoUrl ?? ''}
                    variant="circular"
                  />
                </span>
                <span style={{ paddingLeft: '0.7rem', fontWeight: 'bold' }}>
                  {`${item.user.firstName} ${item.user.lastName}`}
                </span>
              </div>
            ),
          },
          {
            key: 'training',
            heading: 'About',
            primary: true,
            width: '25%',
            render: (item: Application) => (
              <div>
                <span
                  style={{
                    display: 'block',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '20rem',
                    fontWeight: 'bold',
                  }}
                  title={item.training ? item.training.title : item.event.title}
                >
                  {item.training ? item.training.title : item.event.title}
                </span>
                <span style={{ display: 'block' }}>
                  {item.training ? 'Training' : 'Event'}
                </span>
              </div>
            ),
          },
          {
            key: 'user',
            heading: 'Team',
            primary: true,
            width: '10%',
            render: (item: Application) => (
              <span style={{ whiteSpace: 'nowrap' }}>{item.user.team}</span>
            ),
          },
          {
            key: 'user',
            heading: 'Group',
            primary: true,
            width: '10%',
            render: (item: Application) => (
              <span style={{ whiteSpace: 'nowrap' }}>{item.user.group}</span>
            ),
          },
          {
            key: 'user',
            heading: 'Department',
            primary: true,
            width: '15%',
            render: (item: Application) => (
              <span style={{ whiteSpace: 'nowrap' }}>
                {item.user.department}
              </span>
            ),
          },
          {
            key: 'createdOn',
            heading: 'Submitted',
            primary: true,
            width: '15%',
            render: (item: Application) => (
              <span style={{ whiteSpace: 'nowrap' }}>
                {dayjs.utc(item.createdOn).format('MMM D, YYYY')}
              </span>
            ),
          },
          {
            key: 'training',
            heading: 'Cost (€)',
            primary: true,
            width: '5%',
            render: (item: Application) => (
              <span style={{ whiteSpace: 'nowrap' }}>
                {item.training ? item.training.cost : item.event.cost}
              </span>
            ),
          },
        ]}
      />
    </Stack>
  );
};

export default CostsTab;
