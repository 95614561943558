import { useEffect } from 'react';
import { useParams } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Stack } from '@mui/material';
import {
  useEditPathMutation,
  useGetPathQuery,
  useUploadFilesToPathMutation,
} from '../../rtk/api/pathApi';
import { PathForm } from '../../forms';
import {
  PathSchema,
  PathSchemaType,
} from '../../cms/validation/pathValidation';
import { Loader } from '../../common/components';
import './EditPathPage.scss';
import { ModalTypes, PathItemTypes } from '../../cms/enums';
import {
  assignTypeToPathItem,
  generateFileFormData,
  generatePathItem,
} from '../../cms/Utils';
import {
  CreatePathEventDto,
  CreatePathItemDto,
  CreatePathTrainingDto,
  EditPathDto,
} from '../../cms/types/pathTypes';
import { useAppDispatch } from '../../rtk/hooks';
import { openModal } from '../../rtk/features/modalsSlice';
import {
  FAILED_REQUEST_MESSAGE,
  MODAL_VALUES,
  PATHS_LABELS,
  ROUTE_LINKS,
} from '../../cms';
import { useNavigateBack } from '../../common/hooks/useNavigateBack';

const EditPathPage = () => {
  const dispatch = useAppDispatch();
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const pathId: number = id ? +id : -1;
  const { data: path, isError } = useGetPathQuery(pathId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });
  const {
    name = '',
    durationInMonths = 0,
    description = '',
    type,
    trainings = [],
    events = [],
    items = [],
  } = path || {};
  const [editPath, { error: editPathErrors }] = useEditPathMutation();
  const [uploadFilesToPath] = useUploadFilesToPathMutation();

  const methods = useForm<PathSchemaType>({
    resolver: zodResolver(PathSchema),
    defaultValues: {
      name,
      description,
      durationInMonths,
      type,
      items: [],
    },
  });

  const pathItems = [
    ...assignTypeToPathItem(trainings, PathItemTypes.TRAINING),
    ...assignTypeToPathItem(events, PathItemTypes.EVENT),
    ...items,
  ]
    .sort((a, b) => a.position - b.position)
    .map((item) => generatePathItem(item));

  useEffect(() => {
    if (path) {
      methods.reset({
        name,
        description: description ?? '',
        durationInMonths,
        type,
        items: pathItems,
      });
    }
  }, [path]);

  useEffect(() => {
    if (!editPathErrors) return;

    if (Array.isArray(editPathErrors) && editPathErrors.length > 0) {
      editPathErrors.forEach(({ name, message }: any) => {
        methods.setError(name, {
          type: 'server',
          message,
        });
      });
    } else {
      dispatch(
        openModal({
          modalType: ModalTypes.ERROR_MESSAGE,
          modalData: FAILED_REQUEST_MESSAGE,
          timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
        }),
      );
    }
  }, [editPathErrors]);

  useEffect(() => {
    if (isError) navigateBack({ fallback: ROUTE_LINKS.PATHS });
  }, [isError]);

  const onClose = () => {
    navigateBack({ fallback: ROUTE_LINKS.PATHS });
  };

  const handleSubmit = async (values: PathSchemaType) => {
    const newFiles = values.items.filter(
      (item) => item.type === PathItemTypes.FILE && item.data.file,
    );
    const newFileIds = newFiles
      .filter((item) => item.data.id)
      .map((item) => item.data.id);

    const oldFiles = values.items.filter(
      (item) => item.type === PathItemTypes.FILE && item.data.url,
    );
    const oldFileIds = oldFiles.map((item) => item.data.id);

    const deletedFileIds = items
      .filter((item) => item.type === PathItemTypes.FILE)
      .map((item) => item.id)
      .filter((item) => item && !oldFileIds.includes(item))
      .concat(newFileIds);

    const editedTrainings: CreatePathTrainingDto[] = values.items
      .filter((item) => item.type === PathItemTypes.TRAINING)
      .map(({ data, position }) => ({
        trainingId: data.id,
        position: position!,
      }));

    const editedEvents: CreatePathEventDto[] = values.items
      .filter((item) => item.type === PathItemTypes.EVENT)
      .map(({ data, position }) => ({ eventId: data.id, position: position! }));

    const editedItems: CreatePathItemDto[] = values.items
      .filter(
        (item) =>
          item.type !== PathItemTypes.TRAINING &&
          item.type !== PathItemTypes.EVENT &&
          !item.data.file,
      )
      .map((item) => {
        return {
          id: item.data.id,
          name: item.data.name,
          description: item.data.description,
          startDateTime: item.data.startDateTime,
          url: item.data.url,
          type: item.type,
          position: item.position!,
        };
      });

    const model: EditPathDto = {
      ...values,
      id: Number(pathId),
      trainings: editedTrainings,
      events: editedEvents,
      items: editedItems,
      deletedFiles: deletedFileIds,
    };

    try {
      await editPath(model).unwrap();

      if (newFiles.length) {
        const filesFormData = generateFileFormData(newFiles);

        await uploadFilesToPath({ filesFormData, pathId: Number(pathId) });
      }

      dispatch(
        openModal({
          modalType: ModalTypes.SUCCESS_MESSAGE,
          modalData: PATHS_LABELS.EDITED_PATH_TEXT,
          timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
        }),
      );

      setTimeout(onClose, MODAL_VALUES.MODAL_CLOSE_TIMEOUT);
    } catch (err) {}
  };

  if (!path) return <Loader />;

  return (
    <Container maxWidth="lg">
      <Stack className="edit-path-page" spacing="1rem">
        <FormProvider {...methods}>
          <PathForm
            onSubmit={handleSubmit}
            onCancel={onClose}
            title="Edit Path"
            submitBtnLabel="Edit"
            isEditMode
          />
        </FormProvider>
      </Stack>
    </Container>
  );
};

export default EditPathPage;
