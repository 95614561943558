import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type MyEmployeesApplicationsPaginationState = {
  pageNumber: number;
  pageSize: number;
  searchText: string;
  refetch: number;
};

const initialState: MyEmployeesApplicationsPaginationState = {
  pageNumber: 1,
  pageSize: 18,
  searchText: '',
  refetch: 0,
};

const myEmployeesApplicationsPaginationSlice = createSlice({
  name: 'myEmployeesApplicationsPagination',
  initialState,
  reducers: {
    incrementMyEmployeesApplicationsPageNumber: (state: MyEmployeesApplicationsPaginationState) => {
      state.pageNumber++;
    },
    setMyEmployeesApplicationsSearchText: (
      state: MyEmployeesApplicationsPaginationState,
      { payload }: PayloadAction<string>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        searchText: payload,
      };
    },
    setMyEmployeesApplicationsFromDate: (
      state: MyEmployeesApplicationsPaginationState,
      { payload }: PayloadAction<Date>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        dateFrom: payload,
      };
    },
    setMyEmployeesApplicationsToDate: (
      state: MyEmployeesApplicationsPaginationState,
      { payload }: PayloadAction<Date>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        dateTo: payload,
      };
    },
    resetMyEmployeesApplicationPagination: () => initialState,
  },
});

export const {
  incrementMyEmployeesApplicationsPageNumber,
  setMyEmployeesApplicationsSearchText,
  setMyEmployeesApplicationsFromDate,
  setMyEmployeesApplicationsToDate,
  resetMyEmployeesApplicationPagination,
} = myEmployeesApplicationsPaginationSlice.actions;
export default myEmployeesApplicationsPaginationSlice.reducer;
