import { useState } from 'react';
import { Stack, Typography, Divider, Button } from '@mui/material';
import { BUTTON_LABELS } from '../../../cms';
import CustomTextField from '../CustomTextField/CustomTextField';
import {
  useGetNotificationsQuery,
  useMarkNotificationAsReadMutation,
} from '../../../rtk/api/notificationApi';
import {
  useApproveApplicationMutation,
  useRejectApplicationMutation,
} from '../../../rtk/api/applicationApi';
import { ApplicationType } from '../../../cms/enums';

type ApplicationActionModalProps = {
  actionType: 'Approve' | 'Reject';
  applicationType: 'Training' | 'Event';
  applicationId: number;
  title: string;
  applicantName: string;
  onClose: () => void;
};

const ApplicationActionModal = ({
  actionType,
  applicationType,
  applicationId,
  title,
  applicantName,
  onClose,
}: ApplicationActionModalProps) => {
  const { data: notifications } = useGetNotificationsQuery();
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();
  const [approveApplication] = useApproveApplicationMutation();
  const [rejectApplication] = useRejectApplicationMutation();
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);

  const lowerNotificationCount = () => {
    const notificationToMark = notifications?.find(
      (n) => n.applicationId === applicationId,
    );
    if (notificationToMark) {
      markNotificationAsRead({
        partitionKey: notificationToMark.partitionKey,
        rowKey: notificationToMark.rowKey,
      });
    }
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.currentTarget.value);
  };

  const handleSubmit = () => {
    if (actionType === 'Reject' && !comment.length) {
      setError(true);
      return;
    }

    if (actionType === 'Approve') {
      if (applicationType === 'Training') {
        approveApplication({
          applicationId,
          applicationType: ApplicationType.TRAININIG,
          comment: comment.trim(),
        });
      } else {
        approveApplication({
          applicationId,
          applicationType: ApplicationType.EVENT,
          comment: comment.trim(),
        });
      }
    }

    if (actionType === 'Reject') {
      if (applicationType === 'Training') {
        rejectApplication({
          applicationId,
          applicationType: ApplicationType.TRAININIG,
          comment: comment.trim(),
        });
      } else {
        rejectApplication({
          applicationId,
          applicationType: ApplicationType.EVENT,
          comment: comment.trim(),
        });
      }
    }

    lowerNotificationCount();
    onClose();
  };

  return (
    <Stack width="500px" maxWidth="100%">
      <Typography
        fontWeight="500"
        sx={{
          marginRight: 'auto !important',
          padding: '1rem',
          paddingLeft: '1.6rem',
        }}
      >
        {`${actionType} Request`}
      </Typography>

      <Divider flexItem />

      <Stack gap="2rem" padding="2rem 2.5rem">
        <Typography fontSize="14px">
          You are about to {actionType === 'Approve' ? 'approve' : 'reject'} the
          aplication <b>{`"${title}"`}</b>
          {` from ${applicantName}`}
        </Typography>
        <CustomTextField
          label={`Your Comment (${
            actionType === 'Approve' ? 'Optional' : 'Required'
          })`}
          color="secondary"
          multiline
          rows={5}
          fullWidth
          error={error}
          value={comment}
          className="comment-textarea"
          onChange={handleCommentChange}
        />
        <Stack
          flexDirection="row"
          gap="1rem"
          justifyContent="flex-end"
          marginTop="7rem"
        >
          <Button variant="outlined" onClick={onClose}>
            {BUTTON_LABELS.CANCEL}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className="submit-button"
          >
            {BUTTON_LABELS.SUBMIT_BUTTON}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ApplicationActionModal;
