import dayjs from 'dayjs';
import Papa from 'papaparse';
import DOMPurify from 'dompurify';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { unescape } from 'lodash';
import {
  APPLICATION_NOTIFICATION_LABELS,
  COLORS,
  TRAINING_TYPES,
  ERROR_TYPES,
  NOT_AVAILABLE_TEXT,
  DATE_FORMATS,
} from '.';
import {
  ENTITY_TYPES,
  NOTIFICATION_TYPES,
  ExternalProviders,
  SkillTypes,
  ApplicationStatuses,
  PathItemTypes,
  ApplicationStatusesNumbers,
} from './enums';
import { EventErrorsState, ServerError, TrainingErrorsState } from './types';
import techSkillsImage from '../common/images/tech-skills-updated.svg';
import managementSkillsImage from '../common/images/management-skills-updated.svg';
import softSkillsImage from '../common/images/soft-skills-updated.svg';
import careerImage from '../common/images/career.png';
import otherImage from '../common/images/other-skills-updated.svg';
import pluralsightImage from '../common/images/logo-bigger-pluralsight.png';
import udemyImage from '../common/images/logo-udemy.svg';
import { AgendaItemResponse } from './types/eventTypes';
import {
  PathEvent,
  PathTraining,
  GenericContentItem,
  PathResponse,
} from './types/pathTypes';
import { PathItemSchemaType } from './validation/pathValidation';
import { Application, ApplicationForExport } from './types/applcationTypes';

export const addSmallStyle = (isScreenSmall: boolean): string =>
  isScreenSmall ? '-small' : '';

export const addExtraSmallStyle = (isScreenExtraSmall: boolean): string =>
  isScreenExtraSmall ? '-extra-small' : '';

export const createTrainingsChartData = () => [
  {
    value: 0,
    name: TRAINING_TYPES[0].name,
    type: TRAINING_TYPES[0].type,
    fill: COLORS.SCOOTER,
  },
  {
    value: 0,
    name: TRAINING_TYPES[1].name,
    type: TRAINING_TYPES[1].type,
    fill: COLORS.PALE_VIOLET_RED,
  },
  {
    value: 0,
    name: TRAINING_TYPES[2].name,
    type: TRAINING_TYPES[2].type,
    fill: COLORS.YELLOW,
  },
  {
    value: 0,
    name: TRAINING_TYPES[3].name,
    type: TRAINING_TYPES[3].type,
    fill: COLORS.OCEAN_GREEN,
  },
];

export const calculatePercentage = (
  value: number,
  totalValue: number,
): number => (value / totalValue) * 100;

export const extractInitials = (
  namesString: string | null | undefined,
): string => {
  if (namesString) {
    const names = namesString.split(' ');
    let initials = names[0].substring(0, 1);

    if (names.length > 1) initials += names[names.length - 1].substring(0, 1);

    return initials.toUpperCase();
  }

  return NOT_AVAILABLE_TEXT;
};

export const getNotificationMessage = (
  notificationType: number,
  entityType: number,
) => {
  switch (notificationType) {
    case NOTIFICATION_TYPES.APPLIED:
      return entityType === ENTITY_TYPES.DEMO_REQUEST
        ? APPLICATION_NOTIFICATION_LABELS.APPLIED_FOR_DEMO_REQUEST
        : APPLICATION_NOTIFICATION_LABELS.APPLIED_FOR_APPLICATION;
    case NOTIFICATION_TYPES.APPROVED:
      return APPLICATION_NOTIFICATION_LABELS.APPROVED_APPLICATION;
    case NOTIFICATION_TYPES.REJECTED:
      return APPLICATION_NOTIFICATION_LABELS.REJECTED_APPLICATION;
    case NOTIFICATION_TYPES.ASSIGNED:
      return APPLICATION_NOTIFICATION_LABELS.ASSIGNED;
    case NOTIFICATION_TYPES.REMOVE_USER_FROM_EVENT:
      return APPLICATION_NOTIFICATION_LABELS.REMOVED;
    case NOTIFICATION_TYPES.REMOVE_USER_FROM_TRAINING:
      return APPLICATION_NOTIFICATION_LABELS.REMOVED;
    case NOTIFICATION_TYPES.ASSIGNED_PATH:
      return APPLICATION_NOTIFICATION_LABELS.ASSIGNED;
    default:
      return '';
  }
};

export const sanitizeData = (data: any): any => {
  try {
    if (typeof data === 'string') {
      const unicodeDecoded = DOMPurify.sanitize(decodeUnicode(data));
      return unescape(unicodeDecoded);
    } else if (Array.isArray(data)) {
      return data.map((e) => sanitizeData(e));
    } else if (typeof data === 'object' && data != null) {
      Object.keys(data).forEach((key) => {
        data[key] = sanitizeData(data[key]);
      });
      return data;
    }

    // Dont sanitize unsupported types
    throw new Error();
  } catch (e) {
    return data;
  }
};

export const decodeUnicode = (input: string): string => {
  try {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) =>
      String.fromCharCode(parseInt(b, 16)),
    );
  } catch (e) {
    return input;
  }
};

export const genereateDefaultEventErrorsState = (): EventErrorsState => ({
  title: [],
  type: [],
  startDate: [],
  endDate: [],
  applyBeforeDate: [],
  url: [],
  cost: [],
  location: [],
  eventLocation: [],
});

export const retrieveExternalProvider = (
  url: string,
): ExternalProviders | null => {
  let result = null;

  if (url && url.indexOf(ExternalProviders.UDEMY) > -1) {
    result = ExternalProviders.UDEMY;
  } else if (url && url.indexOf(ExternalProviders.PLURALSIGHT) > -1) {
    result = ExternalProviders.PLURALSIGHT;
  }

  return result;
};

export function isApiError(
  error: unknown,
): error is { data: any; status: number } {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    'data' in error &&
    Array.isArray(error.data)
  );
}

export const genereateDefaultTrainingErrorsState = (): TrainingErrorsState => ({
  title: [],
  lessons: [],
  level: [],
  duration: [],
  type: [],
  location: [],
  startDate: [],
  endDate: [],
  applyBeforeDate: [],
  url: [],
  cost: [],
  applicationsLimit: [],
});

export const addToFormErrors = (apiError: unknown, formErrors: any) => {
  if (isApiError(apiError)) {
    apiError.data?.forEach(({ key, value }: { key: string; value: any }) => {
      const index = key.toLowerCase();

      formErrors[index] = [
        ...formErrors[index],
        ...value.map((v: any) => ({
          type: ERROR_TYPES.SERVER,
          message: v.errorMessage,
        })),
      ];
    });
  }

  return { ...formErrors };
};

// Only some of the server error responses are in this format so far!
export const transformErrorResponse = (errorResponse: any): ServerError[] => {
  try {
    return errorResponse.data.map((err: any) => ({
      name: err.key.toLowerCase(),
      message: err.value[0].errorMessage,
    }));
  } catch (e) {
    return [];
  }
};

export const generateSkillTypeImage = (
  applicationType: ExternalProviders | string,
): { src: string; alt: string } => {
  switch (applicationType) {
    case SkillTypes.CAREER:
      return {
        src: careerImage,
        alt: SkillTypes.CAREER,
      };
    case SkillTypes.MANAGEMENT_SKILLS:
      return {
        src: managementSkillsImage,
        alt: SkillTypes.MANAGEMENT_SKILLS,
      };
    case SkillTypes.SOFT_SKILLS:
      return {
        src: softSkillsImage,
        alt: SkillTypes.SOFT_SKILLS,
      };
    case SkillTypes.OTHER:
      return {
        src: otherImage,
        alt: SkillTypes.OTHER,
      };
    case ExternalProviders.PLURALSIGHT:
      return {
        src: pluralsightImage,
        alt: ExternalProviders.PLURALSIGHT,
      };
    case ExternalProviders.UDEMY:
      return {
        src: udemyImage,
        alt: ExternalProviders.UDEMY,
      };
    default:
      return {
        src: techSkillsImage,
        alt: SkillTypes.TECHNICAL_SKILLS,
      };
  }
};

export const getApplicationsStatusColor = (status: ApplicationStatuses) => {
  switch (status) {
    case ApplicationStatuses.IN_PROGRESS:
      return COLORS.PRIMARY;
    case ApplicationStatuses.DROPPED:
      return COLORS.ERROR;
    default:
      return COLORS.SUCCESS;
  }
};

export const getEventDatePeriod = (startDate: Date, endDate: Date): string => {
  if (dayjs(startDate).isSame(endDate, 'day')) {
    return dayjs(startDate).format(DATE_FORMATS.SHORT_DAY_MONTH_YEAR);
  } else if (dayjs(startDate).isSame(endDate, 'month')) {
    return `${dayjs(startDate).format(DATE_FORMATS.SHORT_DAY_MONTH)} - ${dayjs(
      endDate,
    ).format(DATE_FORMATS.SHORT_DAY_MONTH_YEAR)}`;
  } else if (dayjs(startDate).isSame(endDate, 'year')) {
    return `${dayjs(startDate).format(DATE_FORMATS.SHORT_DAY_MONTH)} - ${dayjs(
      endDate,
    ).format(DATE_FORMATS.SHORT_DAY_MONTH_YEAR)}`;
  }

  return `${dayjs(startDate).format(
    DATE_FORMATS.SHORT_DAY_MONTH_YEAR,
  )} - ${dayjs(endDate).format(DATE_FORMATS.SHORT_DAY_MONTH_YEAR)}`;
};

export const getEventTimePeriod = (
  agenda: AgendaItemResponse[],
): string | undefined => {
  let startTime: Date | null = null;
  let endTime: Date | null = null;

  if (agenda && agenda.length !== 0) {
    const firstEventItems = agenda[0]?.eventItems;
    const lastEventItems = agenda[agenda.length - 1]?.eventItems;

    if (firstEventItems && firstEventItems.length !== 0) {
      startTime = firstEventItems[0]?.startTime;
    }

    if (lastEventItems && lastEventItems.length !== 0) {
      endTime = lastEventItems[lastEventItems.length - 1]?.endTime;
    }

    if (!startTime || !endTime) return undefined;

    return `${dayjs(startTime).format(DATE_FORMATS.HOUR_MINUTES)} to ${dayjs(
      endTime,
    ).format(DATE_FORMATS.HOUR_MINUTES)}`;
  }

  return undefined;
};

export const getPathItemSubTitle = (item: any): string => {
  switch (item.type) {
    case PathItemTypes.TRAINING:
      return `${item.location} | ${item.cost > 0 ? 'Paid' : 'Free'} | Vendor: ${
        item.vendor
      }`;
    case PathItemTypes.EVENT:
      return `${item.location} | ${
        item.cost > 0 ? 'Paid' : 'Free'
      } | ${getEventDatePeriod(item.startDate, item.endDate)} `;
    default:
      return item.description;
  }
};

export const generatePathItem = (
  item: GenericContentItem | PathEvent | PathTraining,
): PathItemSchemaType => {
  const title = 'title' in item ? item.title : item.name;
  const url = 'url' in item ? item.url : '';

  return {
    ...item,
    type: item.type,
    displayData: {
      title,
      subTitle: getPathItemSubTitle(item),
    },
    data: {
      ...item,
      id: item.id,
      url,
      name: title,
    },
  };
};

export const getFileExtensionFromUrl = (fileUrl: string) => {
  return fileUrl.split('.').pop();
};

export const getFileFromUrl = (fileUrl: string, fileName: string) =>
  fetch(fileUrl)
    .then((res) => res.blob())
    .then((blob) => {
      return new File(
        [blob],
        `${fileName}.${getFileExtensionFromUrl(fileUrl)}`,
        { type: blob.type },
      );
    })
    .catch(() => {
      return null;
    });

export const convertServerPathEventToClientPathEvent = (
  event: any,
): PathItemSchemaType => ({
  position: event.position,
  type: PathItemTypes.EVENT,
  status: event.status,
  data: {
    id: event.id,
  },
  displayData: {
    title: event.title,
    subTitle: `${event.location} | ${
      event.cost > 0 ? 'Paid' : 'Free'
    } | ${getEventDatePeriod(event.startDate, event.endDate)}`,
    duration: getEventTimePeriod(event.agenda),
  },
});

export const convertServerPathTrainingToClientPathTraining = (
  training: any,
): PathItemSchemaType => ({
  position: training.position,
  type: PathItemTypes.TRAINING,
  status: training.status,
  data: {
    id: training.id,
  },
  displayData: {
    title: training.title,
    subTitle: `${training.location} | ${
      training.cost > 0 ? 'Paid' : 'Free'
    } | Vendor: ${training.vendor}`,
    rating: training.rating,
  },
});

export const convertServerPathLinkToClientPathLink = (
  link: any,
): PathItemSchemaType => {
  const description = link.description ?? undefined;

  return {
    position: link.position,
    type: PathItemTypes.LINK,
    status: link.status,
    data: {
      name: link.name,
      description,
      url: link.url,
    },
    displayData: {
      title: link.name,
      subTitle: description,
    },
  };
};

export const assignTypeToPathItem = (
  contentItems: PathTraining[] | PathEvent[],
  type: PathItemTypes,
) => contentItems.map((item) => ({ ...item, type }));

export const convertServerPathMeetingToClientPathMeeting = (
  meeting: any,
): PathItemSchemaType => {
  const description = meeting.description ?? undefined;

  return {
    position: meeting.position,
    type: PathItemTypes.MEETING,
    status: meeting.status,
    data: {
      name: meeting.name,
      description,
      startDateTime: meeting.startDateTime,
    },
    displayData: {
      title: meeting.name,
      subTitle: description,
    },
  };
};

export const convertServerPathFileToClientPathFile = async (
  file: any,
): Promise<PathItemSchemaType> => {
  const description = file.description ?? undefined;
  const localFile = await getFileFromUrl(file.url, file.name);

  return {
    position: file.position,
    type: PathItemTypes.FILE,
    status: file.status,
    data: {
      name: file.name,
      description,
      file: localFile,
    },
    displayData: {
      title: file.name,
      subTitle: description,
    },
  };
};

export const convertServerPathToPathFormValues = async (path: PathResponse) => {
  const eventItems: PathItemSchemaType[] = path.events.map((event) =>
    convertServerPathEventToClientPathEvent(event),
  );

  const trainingItems: PathItemSchemaType[] = path.trainings.map((training) =>
    convertServerPathTrainingToClientPathTraining(training),
  );

  const linkItems: PathItemSchemaType[] = path.items
    .filter((i) => i.type === PathItemTypes.LINK)
    .map((link) => convertServerPathLinkToClientPathLink(link));

  const meetingItems: PathItemSchemaType[] = path.items
    .filter((i) => i.type === PathItemTypes.MEETING)
    .map((meeting) => convertServerPathMeetingToClientPathMeeting(meeting));

  const fileItems: PathItemSchemaType[] = await Promise.all(
    path.items
      .filter((i) => i.type === PathItemTypes.FILE)
      .map(async (file) => convertServerPathFileToClientPathFile(file)),
  );

  return {
    name: path.name,
    description: path.description ?? undefined,
    durationInMonths: path.durationInMonths,
    type: path.type,
    items: [
      ...eventItems,
      ...trainingItems,
      ...linkItems,
      ...meetingItems,
      ...fileItems,
    ].sort((a, b) => a.position! - b.position!),
  };
};

export const generateFileFormData = (
  fileItems: PathItemSchemaType[],
): FormData => {
  const filesFormData = new FormData();

  fileItems.forEach((item, index) => {
    const { file, name, description } = item.data;

    filesFormData.append(`file${index}`, file);
    filesFormData.append(
      `file${index}Model`,
      JSON.stringify({
        name,
        description,
        position: item.position,
      }),
    );
  });

  return filesFormData;
};

export const inheritFontStyle: SxProps<Theme> = {
  font: 'inherit',
  fontSize: 'inherit',
};

export const getApplicationStatusFromNumber = (
  statusNumber: ApplicationStatusesNumbers,
): ApplicationStatuses => {
  switch (statusNumber) {
    case ApplicationStatusesNumbers.PENDING:
      return ApplicationStatuses.PENDING;
    case ApplicationStatusesNumbers.IN_PROGRESS:
      return ApplicationStatuses.IN_PROGRESS;
    case ApplicationStatusesNumbers.APPROVED:
      return ApplicationStatuses.APPROVED;
    case ApplicationStatusesNumbers.COMPLETED:
      return ApplicationStatuses.COMPLETED;
    default:
      return ApplicationStatuses.DROPPED;
  }
};

export const convertToCsv = (data: any[]) => {
  const csv = Papa.unparse(data);
  return csv;
};

export const downloadFile = (
  file: string,
  fileName: string,
  mimeType: string,
) => {
  const fileBlob = new Blob([file], { type: mimeType });
  const fileUrl = URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.setAttribute('href', fileUrl);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFileNameFromFileUrl = (url: string) => {
  if (url) {
    return url.split('/').pop();
  }

  return '';
};

export const formatTrainingApplicationsForExport = (
  applications: Application[],
): ApplicationForExport[] => {
  return applications.map(
    ({
      status,
      approvedOn,
      rejectedOn,
      startedOn,
      completedOn,
      createdOn,
      lastUpdatedAt,
      training,
      user,
    }) => {
      return {
        title: training.title,
        status: ApplicationStatusesNumbers[Number(status)],
        type: training.type,
        cost: training.cost,
        url: training.url,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        jobTitle: user.jobTitle,
        group: user.group,
        team: user.team,
        manager: user.manager,
        approvedOn: approvedOn
          ? dayjs(approvedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        rejectedOn: rejectedOn
          ? dayjs(rejectedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        startedOn: startedOn
          ? dayjs(startedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        completedOn: completedOn
          ? dayjs(completedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        createdOn: createdOn
          ? dayjs(createdOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        lastUpdatedAt: lastUpdatedAt
          ? dayjs(lastUpdatedAt).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
      };
    },
  );
};

export const formatEventApplicationsForExport = (
  applications: Application[],
): ApplicationForExport[] => {
  return applications.map(
    ({
      status,
      approvedOn,
      rejectedOn,
      startedOn,
      completedOn,
      createdOn,
      lastUpdatedAt,
      event,
      user,
    }) => {
      return {
        title: event.title,
        status: ApplicationStatusesNumbers[Number(status)],
        type: event.type,
        cost: event.cost,
        url: event.url,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        jobTitle: user.jobTitle,
        group: user.group,
        team: user.team,
        manager: user.manager,
        approvedOn: approvedOn
          ? dayjs(approvedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        rejectedOn: rejectedOn
          ? dayjs(rejectedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        startedOn: startedOn
          ? dayjs(startedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        completedOn: completedOn
          ? dayjs(completedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        createdOn: createdOn
          ? dayjs(createdOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        lastUpdatedAt: lastUpdatedAt
          ? dayjs(lastUpdatedAt).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
      };
    },
  );
};

export const formatMyEmployeesApplicationsExport = (
  applications: Application[],
): ApplicationForExport[] => {
  return applications.map(
    ({
      status,
      approvedOn,
      rejectedOn,
      startedOn,
      completedOn,
      createdOn,
      lastUpdatedAt,
      event,
      training,
      user,
    }) => {
      return {
        title: training ? training.title : event.title,
        status: ApplicationStatusesNumbers[Number(status)],
        type: training ? training.type : event.type,
        cost: training ? training.cost : event.cost,
        url: training ? training.url : event.url,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        jobTitle: user.jobTitle,
        group: user.group,
        team: user.team,
        manager: user.manager,
        approvedOn: approvedOn
          ? dayjs(approvedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        rejectedOn: rejectedOn
          ? dayjs(rejectedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        startedOn: startedOn
          ? dayjs(startedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        completedOn: completedOn
          ? dayjs(completedOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        createdOn: createdOn
          ? dayjs(createdOn).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
        lastUpdatedAt: lastUpdatedAt
          ? dayjs(lastUpdatedAt).format(DATE_FORMATS.SIMPLE_SLASH)
          : null,
      };
    },
  );
};
